import EmpModal from "../../../../../../components/modal/Modal";
import React from "react";
import { useAtom } from "jotai/index";
import { previewImgModal_atom } from "../../../../../../data/atom/workManage/transactionRegisterManage/documentManage_atom";

export const PreviewImg = () => {
  const [previewImgModal, setPreviewImgModal] = useAtom(previewImgModal_atom);

  return (
    <EmpModal
      show={previewImgModal.isOpen}
      width="100%"
      // minWidth="475px"
      title="미리보기"
      onHide={() => setPreviewImgModal(prev => ({ ...prev, isOpen: false }))}
      // strClass="modal-xl"
      // size="xl"
    >
      <img
        className="bd-placeholder-img card-img-top cursor-pointer"
        width="100%"
        // height="180px"
        src={previewImgModal.data}
      />
    </EmpModal>
  );
};
